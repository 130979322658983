import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Logo from '../components/logo'
const Header = ({ siteTitle }) => (
  <div>
  <div
    class="header"
  >
    <div
      style={{
        margin: '0 auto',
        maxWidth: 960,
        padding: '.45rem .0875rem',
      }}
    >
      <h1 class="body-text" style={{ margin: 0 }}>
      <div class="level-header">
        <div class="left-title">
            Bespoke
        </div>
        <div class="header-logo">
            <Logo /> 
        </div>
        <div class="right-title">
           Crafters
        </div>
      </div>
      </h1>
    </div>
  </div>
  <hr class="divider"></hr>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
